import React, { useState } from 'react';
import { classes, st } from './BackButton.st.css';
import { BackButtonDataHooks } from './dataHooks';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import ChevronLeft from 'wix-ui-icons-common/on-stage/ChevronLeftSmall';
import ChevronRight from 'wix-ui-icons-common/on-stage/ChevronRightSmall';
import PreviewTooltip from '../PreviewTooltip/PreviewTooltip';

export interface BackButtonProps {}
const BackButton: React.FC<BackButtonProps> = () => {
  const { t } = useTranslation();
  const { isRTL, isMobile } = useEnvironment();
  const handleOnClick = () => window.history.back();

  return (
    <div
      className={st(classes.root, {
        isMobile,
      })}
    >
      <PreviewTooltip content={t('app.preview.tooltips.back-button')}>
        <TextButton
          priority={TEXT_BUTTON_PRIORITY.primary}
          className={classes.backButton}
          data-hook={BackButtonDataHooks.BACK_BUTTON}
          onClick={handleOnClick}
          prefixIcon={
            isRTL ? <ChevronRight aria-hidden /> : <ChevronLeft aria-hidden />
          }
        >
          {t('app.back-button.label')}
        </TextButton>
      </PreviewTooltip>
    </div>
  );
};

export default BackButton;
